import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const indignation = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Indignation" />
    <h3 className='underline-title'>Indignation</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Père<br />
        Ils volent toujours<br />
        Ils se liguent pour voler<br />
        Ils mentent trop<br />
        Ils s'entendent encore pour mentir<br />
      </p>
      <p>
        Père<br />
        Auraient-ils faim?<br />
        Ils ne sont pas si affamés pourtant<br />
        Les Sahéliens ne sont pas si voleurs!<br />
        Auraient-ils mauvaise intention?<br />
        Ils ont l'air aimable pourtant<br />
        Les Mongols ne sont pas si ravageurs!<br />
      </p>
      <p> 
        Père<br />
        Ils sont de la terre<br />
        <span className="par-starting"></span>d'habitudes terrestres<br />
        Ils sont par terre<br />
        <span className="par-starting"></span>leurs pieds s'enterrent<br />
        <span className="par-starting"></span>leurs mains sont terreuses<br />
        Ils sont terre-à-terre<br />
        <span className="par-starting"></span>esprit terrien<br />
        <span className="par-starting"></span>vers de terre<br />
        <span className="par-starting"></span>plus bas que la terre<br />
      </p>
      <p>
        Père<br />
        Rendez-les sages<br />
        Sages de la terre<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 18 juin 1975</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default indignation
